/** ***************************************************************************
 * Templates / Base
 *************************************************************************** */

:root {
  --hns-c-page--Grid: "main" 1fr;


  /**
   * Site navigation
   */

  --hns-c-page__site-nav--TransitionDuration: calc(3.5 * var(--hns-global--Duration--sm));
  --hns-c-page__site-nav--TransitionTimingFunction: var(--hns-global--AnimationTimingFunction--bounce);
}



/**
 * Component
 */

body {
  display: grid;
  grid: var(--hns-c-page--Grid);
  min-height: 100vh;
}



/**
 * Main content
 */

main {
  grid-area: main;
}



/**
 * Site navigation
 * 1: disable page scroll when the site navigation is open
 * 2: hide site navigation rocks while opening
 */

/* body:has(#nav-toggle:checked) */
body.nav-open {
  overflow-y: hidden;  /* 1 */
}

.hns-c-site-nav {
  --hns-c-site-nav__rock--Opacity: 0;  /* 2 */
  --hns-c-site-nav__rock--TransitionDelay: var(--hns-c-page__site-nav--TransitionDuration);  /* 2 */
  inset: 0;
  overflow: hidden;
  position: fixed;
  transform: translateX(-100%);
  transition: transform var(--hns-c-page__site-nav--TransitionDuration) var(--hns-c-page__site-nav--TransitionTimingFunction);
  z-index: 3;
}

#nav-toggle:checked ~ .hns-c-site-nav {
  --hns-c-site-nav__rock--Opacity: .6;  /* 2 */
  overflow-y: visible;
  transform: translateX(0);
}

@supports not (inset: 0) {
  .hns-c-site-nav {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}



/**
 * Tools overlay
 * 1: above video play button (2)
 * 2: hide language chooser and social links when the menu is open
 */

.hns-c-tools {
  --offset: 3;
  position: absolute;
  right: 50%;
  top: 0;
  transform: translateX(calc(50% + (
    (var(--hns-global--content-grid--gutter-width) + var(--hns-global--content-grid--column-width)) *
    (var(--column-count) - var(--offset))
  ) / 2));
  z-index: 3;  /* 1 */
}

#nav-toggle:checked ~ .hns-c-tools .hns-c-language-chooser,
#nav-toggle:checked ~ .hns-c-tools .hns-c-social-links {
  visibility: hidden;  /* 2 */
}

/**
 * Large
 */

@media all and (min-width: 64rem) {
  .hns-c-tools {
    --offset: 2;
    position: fixed;
  }
}



/**
 * Old code
 */

/* special effects */

  .hns-c-nav-switch svg,
  .hns-c-nav-switch rect {
    transition:
      transform var(--hns-global--Duration--sm) linear,
      fill      var(--hns-global--Duration--sm) linear var(--hns-global--Duration--sm);
  }

  .hns-c-tools {
    transition: color var(--hns-global--Duration--sm) linear var(--hns-global--Duration--sm);
  }

  #nav-toggle:checked ~ .hns-c-tools {
    color: var(--hns-global--palette-white);
    --color-foreground: var(--hns-global--palette-white);
  }

  #nav-toggle:checked ~ .hns-c-tools .hns-c-social-links {
    --hns-c-social-links__icon--BackgroundColor: var(--hns-global--palette-white);
  }

  #nav-toggle:checked ~ .hns-c-tools .hns-c-nav-switch svg {
    transform: rotateZ(45deg);
  }

  #nav-toggle:checked ~ .hns-c-tools .hns-c-nav-switch svg rect {
    transform-origin: center;
  }

  #nav-toggle:checked ~ .hns-c-tools .hns-c-nav-switch svg rect:nth-child(1) {
    transform: translateY(calc(50% - 42px));
  }

  #nav-toggle:checked ~ .hns-c-tools .hns-c-nav-switch svg rect:nth-child(2) {
    transform: scale(0);
  }

  #nav-toggle:checked ~ .hns-c-tools .hns-c-nav-switch svg rect:nth-child(3) {
    transform: rotateZ(90deg) translateY(calc(-1 * (50% - 42px)));
  }
